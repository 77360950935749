<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
  }">

      <div class="wrapper">
        <input type="radio" name="radio" id="opt1">
        <label for="opt1" class="label1">
          <span>Да</span>
        </label>
        <input type="radio" name="radio" id="opt2">
        <label for="opt2" class="label2">
          <span>Не</span>
        </label>
      </div>


  </div>
</template>

