import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";

export default {
    name: 'MakePremium',
    props: {
        adtype: {
            type: String,
            default: ''
        },
        available: {
            type: String,
            default: ''
        },
        promoamount: {
            type: String,
            default: ''
        },
        promodiscount: {
            type: String,
            default: ''
        },
        regularprice: {
            type: String,
            default: ''
        },
        discountedrprice: {
            type: String,
            default: ''
        }
    },
    components: {
        Dropdown
    },
    mounted() {
    }
}