
export default {
    name: 'Pagination',
    props: {
        size: {
            type: String,
            default: 'small'
        },
        pages: {
            type: Number,
            default: 0
        },
        current: {
            type: Number,
            default: 0
        },
        route: {
            type: String,
            default: ''
        },
        routeParams: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            page: 1,
            pagesList: 0
        }
    },
    methods: {
        selectPage(number) {
            if (number === '...') {
                return;
            }
            this.page = number;
            this.$emit('pageChanged', number > 0 ? number - 1 : 0)
            this.updatePagesList();
        },
        isActive(num) {
            return parseInt(this.page) === parseInt(num);
        },
        next() {
            if (this.page >= this.$props.pages - 1) {
                return;
            }
            this.page++;
            this.$emit('pageChanged', this.page)
        },
        prev() {
            if (this.page <= 0) {
                return;
            }
            this.page--;
            this.$emit('pageChanged', this.page)
        },
        updatePagesList() {
            if (this.$props.pages > 10) {
                this.pagesList = [];
                if (this.page < 3 || this.page >= this.$props.pages - 3) {
                    this.pagesList = [1,2,3];
                } else {
                    this.pagesList.push(this.page - 1);
                    this.pagesList.push(this.page);
                    this.pagesList.push(this.page + 1);
                }
                this.pagesList.push('...');
                this.pagesList.push(this.$props.pages - 3);
                this.pagesList.push(this.$props.pages - 2);
                this.pagesList.push(this.$props.pages - 1);
            }
        }
    },
    mounted() {
        this.page = this.$props.current + 1;
        this.pagesList = this.$props.pages;
        this.updatePagesList();
    }
}