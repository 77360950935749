
export default {
    name: 'RadioButton',
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        label: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    mounted() {
    }
}