<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="viewing-inquiry-container d-md-none">
      <div class="inquiry-top-container row ">
        <div class="d-flex">
          <div class="inquiry-name"> {{ name }} </div>
          <div class=" ms-auto">
          <LabelNew :language="'bulgarian'"  v-if="labelnew === 'yes'"/>
        </div>
        </div>

        <div class="inquiry-phone col-3"> {{ phone }} </div>
        <div class="inquiry-email col-8"> {{ email }} </div>
      </div>
      <div class="inquiry-bottom-container row">
        <div class="inquiry-thumbnail col-1"></div>
        <div class="col-10">
          <div class="inquiry-date-time"> {{ date }} | {{ time}} </div>
          <div class="inquiry-title"> [{{ propertyid }}] {{ title }}</div>
        </div>

      </div>
    </div>
    <div class="viewing-inquiry-container-desktop d-none d-md-flex align-items-center">
      <div class="inquiry-name col-3"> {{ name }} </div>
      <div class="inquiry-title">
        <router-link :to="{name: 'SinglePropertyPage', params: {id: propertyid}}" target="_blank">
          {{ title }}
        </router-link>
      </div>
      <LabelNew class="" :language="'bulgarian'"  v-if="labelnew === 'yes'"/>
      <div class="inquiry-email ms-auto"> {{ email }} </div>
      <div class="inquiry-phone"> {{ phone }} </div>
      <div class="inquiry-date-time"> {{ date }} | {{ time}} </div>

    </div>




  </div>
</template>

