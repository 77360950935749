;

import Button from '@/components/Common/Buttons/Button/Button';
import InputField from '@/components/Common/Inputs/Input/Input';
import FileInput from '@/components/Common/Inputs/FileInput/FileInput';
import TextArea from '@/components/Common/Inputs/TextArea/TextArea';
import Dropdown from '@/components/Common/Inputs/Dropdown/Dropdown';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import YesNoInput from '@/components/Common/Inputs/YesNoInput/YesNoInput';
import MakePremium from '@/components/Components/MakePremium/MakePremium/';
import PremiumAdSelection from '@/components/Components/PremiumAdSelection/PremiumAdSelection/';
import ViewingInquiry from '@/components/Components/ViewingInquiry/ViewingInquiry/';
import CreditWallet from '@/components/Components/CreditWallet/CreditWallet/';
import AdUpdate from '@/components/Components/AdUpdate/AdUpdate/';
import Pagination from '@/components/Components/Pagination/Pagination/';
import UserTypeMobile from '@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile';
import UserNavMobile from '@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile';


export default {
    name: 'AdminViewingRequests',
    components: {
        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        ViewingInquiry,
        TextArea,
        Dropdown,
        InputField,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination,
    },
    data() {
        return {
            categoriesMenuActive: false,
            translation: {
                viewing_requests: 'Заявки за оглед',
                add_offer: 'Добави обява',
            },
            inquiries: [],
        };
    },
    methods: {},

    async mounted() {
        this.inquiries = await this.$store.dispatch('propertyView/getInquiries');
    },
};
