<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="">
    <div class="add-property-title"> {{ $t('agency-viewing-requests-title') }}</div>
    <div class="inquiry-item" v-for="inquiry in inquiries">
      <ViewingInquiry :name="inquiry.contactEmail" :phone="inquiry.contactPhone" :email="inquiry.contactEmail"
                      :date="inquiry.dateTime" :time="inquiry.time" :propertyid="inquiry.property.id"
                      :title="inquiry.property.title"
                      :labelnew="inquiry.labelnew"/>
    </div>
    <div class="pagination">
      <Pagination :size="'small'"/>
    </div>
  </div>
</template>
