<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'pagination': true,
    'pagination-size-small': size === 'small',
    'pagination-size-medium': size === 'medium',
    'pagination-size-large': size === 'large',
  }">
    <div class="circle" @click="prev()">
      <div href="#" @click="prev()">&lt;</div>
    </div>
    <div v-for="(num, index) in pagesList" v-bind:class="{'circle': num !== '...', 'active': isActive(num)}"
        :key="'pp' + index" @click="selectPage(num);">
      <div>{{ num }}</div>
    </div>
    <div class="circle" @click="next()">
      <div href="#">&gt;</div>
    </div>
  </div>
</template>

