import RadioButton from '@/components/Common/Inputs/RadioButton/RadioButton';
import Dropdown from '@/components/Common/Inputs/Dropdown/Dropdown';
import ToggleSwitch from '@/components/Common/Buttons/ToggleSwitch/ToggleSwitch';
import InputField from '@/components/Common/Inputs/Input/Input';
import Button from '@/components/Common/Buttons/Button/Button';

export default {
    name: 'AdUpdate',
    props: {
        adtype: {
            type: String,
            default: '',
        },
        date: {
            type: String,
            default: '',
        },
        propertyid: {
            type: String,
            default: '',
        },
        productid: {
            type: String,
            default: '',
        },
        price: {
            type: String,
            default: '',
        },
        discountPrice: {
            type: String,
            default: '',
        },
        formErrors: {
            type: Array,
            default: [],
        },
        status: {
            type: Boolean,
            default: null,
        },
        petFriendly: {
            type: Boolean,
            default: null,
        },
    },
    components: {
        Dropdown,
        ToggleSwitch,
        InputField,
        Button,
    },
    data() {
        return {
        }
    },
    methods: {
        handleInput(e) {
            this.$emit('update:price', e.target.value);
        },
        handleStatusSwitch(e) {
            this.$emit('update:status', e.target.checked);
        },
        handlePetFriendlySwitch(e) {
            this.$emit('update:pet-friendly', e.target.checked);
        },
    },
    computed: {
        regularPrice() {
            return this.price;
        },
        discountedPrice() {
            return this.discountPrice;
        },
    },
};
